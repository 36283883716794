/* eslint-disable no-unused-vars */
import {
  login,
  logout,
  register,
  verifyOTP,
  getDashboardInfo,
} from "@/api/auth";
import router from "@/router";
import store from "@/store";
import { addDynamicRoutes } from "@/router";
import {
  getToken,
  setLogedInUserInfo,
  removeToken,
  setToken,
  removeLogedInUserInfo,
  removeLogedInUser,
} from "@/utils/auth";

const state = {
  token: getToken(),
  user: {
    user_id: "",
    otp: "",
  },
  roles: [],
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
};

const actions = {
  //user register
  register({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      register(userInfo)
        .then((response) => {
          commit("SET_USER", userInfo);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  // user login
  login({ commit }, userInfo) {
    const { user_id, email, sms } = userInfo;
    return new Promise((resolve, reject) => {
      login({ user_id: user_id, email: email, sms: sms })
        .then((response) => {
          commit("SET_USER", userInfo);

          resolve(response);
        })
        .catch((error) => {
          //console.log(error);
          reject(error);
        });
    });
  },

  // verifyOTP for login
  verifyOTP({ commit }, userInfo) {
    const { user_id, otp } = userInfo;
    return new Promise((resolve, reject) => {
      verifyOTP({ user_id: user_id, otp: otp })
        .then(async ({ data }) => {
          commit("SET_TOKEN", data.token);
          setToken(data.token);
          let tempObj = {};
          tempObj.detail = data.user;
          console.log("JSON.stringify(data.user", tempObj);

          setLogedInUserInfo(JSON.stringify(tempObj));
          const Role = data.user.Roles;
          if (Role.length > 0) {
            const roles = Role.map((role) =>
              role.name.replace(/_/g, " ").replace(/-/g, " ").toLowerCase()
            );
            commit("SET_ROLES", roles);
            const accessRoutes = await store.dispatch(
              "permission/generateRoutes",
              roles
            );

            // dynamically add accessible routes
            addDynamicRoutes(accessRoutes);
          }
          resolve(data);
        })
        .catch((error) => {
          console.log(error, "fafafafa");
          reject(error);
        });
    });
  },
  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "");
      commit("SET_ROLES", []);
      removeToken();
      removeLogedInUserInfo();
      removeLogedInUser();
      resolve();
    });
  },
  // get dashboard info
  getDashboardInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getDashboardInfo()
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
